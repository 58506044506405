import React, { useEffect } from "react";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
} from "gatsby-plugin-intl";
import "./src/styles/global.css";

import { GlobalProvider } from "./src/contexts/GlobalContext";

import type { GatsbyBrowser } from "gatsby";

import { ToastContainer } from "react-toastify";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      {/* <IntlProvider locale={localStorage.getItem("locale")} defaultLocale="mn"> */}
      <GlobalProvider>{element}</GlobalProvider>
      {/* </IntlProvider> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};
